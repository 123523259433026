<template>
	<div>
		<div class="header">
			<div class="c">
				<div class="logo" @click="backToHome">
					<img src="https://s.istero.com/images/logo.png" />
				</div>
				<div class="else">
					<el-button type="" :size="size" @click="backToHome">返回首页</el-button>
					<el-button type="" :size="size" @click="openIstero">起零官网</el-button>
					<el-button type="primary" :size="size" @click="openUrl">打开{{allData.web}}</el-button>
				</div>
			</div>
		</div>
		<div class="mainBox">
			<div class="title">{{allData.name}}
			</div>
			<div style="margin-top:5px">
				<el-tag type="warning" style="letter-spacing: 0.4px;">
					V1.0</el-tag>
			</div>
			<div class="desc">{{allData.desc}}</div>
			<div class="textBox">
				<el-input v-model="url" placeholder="在此输入或粘贴地址" size="large" @paste="getPaset" :disabled="loading">
					<template #suffix v-if="!mobile">
						<el-button type="primary" @click="pasteMe" :disabled="loading">粘贴</el-button>
					</template>
				</el-input>
			</div>
			<div class="checkBox">
				<el-checkbox v-model="checked1" label="粘贴后自动获取" size="large" />
			</div>
			<el-button type="primary" size="large" round :loading="loading"
				style="padding:25px 100px;border-radius: 100px;" @click="getVideo">
				<template #loading>
					<div class="custom-loading">
						<svg class="circular" viewBox="-10, -10, 50, 50">
							<path class="path" d="
					            M 30 15
					            L 28 17
					            M 25.61 25.61
					            A 15 15, 0, 0, 1, 15 30
					            A 15 15, 0, 1, 1, 27.99 7.5
					            L 15 15
					          " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)" />
						</svg>
					</div>
				</template>获取视频
			</el-button>
			<!-- //twitter -->
			<div v-if="type == 0">
				<el-card class="cardBox" shadow="never" v-if="show && !mobile">
					<div class="c">
						<div class="imgBox">
							<el-image :src="downList.media.videos[0].thumbnail" style="width: 100%;height:160px"
								fit="cover" :preview-src-list="[downList.media.videos[0].thumbnail]">
							</el-image>
						</div>
						<div class="cBox">
							<div><el-tag type="warning" style="margin-bottom: 8px;">USER
									ID:{{downList.meta.username}}</el-tag></div>
							<div><el-tag type="warning" style="margin-bottom: 8px;">TWITTER
									ID:{{downList.meta.tweet_id}}</el-tag></div>
							<div clas="title">
								{{downList.full_text}}
								<div class="btnBox" v-if="downList.media.videos[0].variants">
									<div style="margin-bottom:5px">清晰度选择:</div>
									<a :href="item.url" v-for="(item,index) in downList.media.videos[0].variants"
										style="margin-right: 5px;" target="_blank">
										<el-button type="warning" plain v-if="item.width != 0">
											{{item.width}}*{{item.height}}
										</el-button>
									</a>
								</div>
							</div>

						</div>
					</div>
				</el-card>
				<el-card class="cardBox" shadow="never" v-else-if="show && mobile">
					<div class="c">
						<div class="imgBox">
							<el-image :src="downList.media.videos[0].thumbnail" style="width: 100%;height:160px"
								fit="cover" :preview-src-list="[downList.media.videos[0].thumbnail]">
							</el-image>
						</div>
						<div class="cBox">
							<div><el-tag type="warning" style="margin-bottom: 8px;">USER
									ID:{{downList.meta.username}}</el-tag></div>
							<div><el-tag type="warning" style="margin-bottom: 8px;">TWITTER
									ID:{{downList.meta.tweet_id}}</el-tag></div>
							<div clas="title">
								{{downList.full_text}}
								<div class="btnBox" v-if="downList.media.videos[0].variants">
									<div style="margin-bottom:5px">清晰度选择:</div>
									<a :href="item.url" v-for="(item,index) in downList.media.videos[0].variants"
										style="margin-right: 5px;" target="_blank">
										<el-button type="warning" plain v-if="item.width != 0">
											{{item.width}}*{{item.height}}
										</el-button>
									</a>
								</div>
							</div>

						</div>
					</div>
				</el-card>
			</div>

			<!-- //ins -->
			<div v-if="type == 1">
				<el-card class="cardBox" shadow="never" v-if="show" v-for="(item,index) in downList">
					<div class="c">
						<div class="imgBox">
							<el-image :src="item.thumb" style="width: 100%;height:160px" fit="cover"
								:preview-src-list="[item.thumb]"></el-image>
						</div>
						<div class="cBox">
							<div clas="title">
								{{item.meta.title}}
								<div>如点击下载资源无效，也可直接保存图片。</div>
								<div class="btnBox">
									<el-button v-for="(items,indexs) in item.url" @click="download(items.url)"
										type="warning" plain>
										下载资源
									</el-button>
								</div>
							</div>

						</div>
					</div>
				</el-card>
			</div>

			<!-- //tiktok -->
			<div v-if="type == 2">
				<el-card class="cardBox" shadow="never" v-if="downList.thumb">
					<div class="c">
						<div class="imgBox">
							<el-image :src="downList.thumb" style="width: 100%;height:160px" fit="cover"
								:preview-src-list="[downList.thumb]"></el-image>
						</div>
						<div class="cBox">
							<el-tag type="warning" style="margin-bottom: 8px;">ID:{{downList.id}}</el-tag>
							<div clas="title">
								{{downList.meta.title}}
								<div>时长：{{downList.meta.duration}}</div>
								<div class="btnBox">
									<div style="margin-bottom:5px">请选择下载的内容：</div>
									<el-button v-for="(item,index) in downList.url" @click="download(item.url)"
										type="warning" plain>
										{{item.name}}
									</el-button>
								</div>
							</div>

						</div>
					</div>
				</el-card>
			</div>
			<div class="collapse">
				<el-collapse v-model="activeNames" @change="handleChange" accordion>
					<el-collapse-item title="1.如何使用本工具" name="1">
						<div>
							{{allData.intro}}
						</div>
					</el-collapse-item>
					<el-collapse-item title="2.本工具是否免费,何时会开始收费" name="2">
						<div>
							本工具目前完全免费，但起零保留后续可能收费的权利，且用且珍惜。
						</div>
					</el-collapse-item>
					<el-collapse-item title="3.下载视频图片是否合法" name="3">
						<div>
							本工具为合法软件，只用于帮助用户下载资源，但所下载的资源我们无法控制，如下载的内容导致后续的法律问题由下载者自行承担。
						</div>
					</el-collapse-item>
					<el-collapse-item title="4.页面为什么会有失效的提示？" name="4">
						<div>
							为保证接口的安全性及用户使用的稳定性，系统每隔一小时会进行一次更新，如弹出页面失效的提示，点击确定刷新页面即可继续使用。
						</div>
					</el-collapse-item>
				</el-collapse>
			</div>
			<div class="footer" v-if="!mobile">
				<el-divider content-position="center">
					<div>Copyright © 2019 - 2024 ISTERO. All Rights Reserved.</div>
					<div class="cLink"><a href="https://beian.miit.gov.cn/" target="_blank">辽ICP备19003788号-1</a></div>
				</el-divider>
			</div>
			<div v-else class="footer">
				<div>Copyright © 2019 - 2024 ISTERO. All Rights Reserved.</div>
				<div class="cLink"><a href="https://beian.miit.gov.cn/" target="_blank">辽ICP备19003788号-1</a></div>
			</div>
		</div>



	</div>
</template>

<script>
	import {
		Eleme
	} from '@element-plus/icons-vue'
	import fileDownload from "js-file-download";
	import {
		onMounted,
		onUpdated,
		reactive,
		watch,
		ref
	} from 'vue'
	import {
		useRouter
	} from "vue-router";
	import {
		useWindowSize
	} from '@vant/use';
	import {
		getAllVideo,
	} from '@/api/home';
	import {
		ElMessage,
		ElNotification,
		ElMessageBox,
	} from 'element-plus'
	import {
		saveAsfrom
	} from 'file-saver';
	import {
		checkVaild
	} from '@/utils/validate.js';

	import clipboard3 from "vue-clipboard3"

	export default {
		name: 'tools',
		props: {
			type: {
				type: String,
				default: "0"
			},
			urls: {
				type: String,
				default: ""
			}
		},
		data() {
			return {
				url: "",
				checked1: true,
				show: false,
				loading: false,
				activeNames: 1,
				downList: {
					full_text: null
				},
				toolList: [{
					name: "X Video Downloader",
					desc: "下载 X / Twitter 视频工具",
					flag: "twitter",
					web: "X",
					url: "https://www.x.com",
					intro: "在X（twitter）中点击想要下载的视频右键--复制视频地址，在本工具对应的文本栏粘贴并获取即可。",
				}, {
					name: "Instagram Source Downloader",
					desc: "下载Instagram视频/图片工具",
					flag: "instagram",
					web: "Instagram",
					url: "https://www.instagram.com",
					intro: "在instagram中点击想要下载的视频右上方“···”，复制链接，在本工具对应的文本栏粘贴并获取即可。",
				}, {
					name: "Tiktok Video Downloader",
					desc: "下载Tiktok无水印视频工具",
					flag: "tiktok",
					web: "Tiktok",
					url: "https://www.tiktok.com",
					intro: "在Tiktok中点击想要下载的视频复制链接，在本工具对应的文本栏粘贴并获取即可。",
				}]
			}
		},
		created() {
			let that = this
			that.allData = that.toolList[that.type];
			if (that.urls) {
				that.url = that.urls
				that.getVideo()
			}
			// if (!sessionStorage.getItem("token")) {
			// 	that.getToken()
			// } else {
			// 	if (that.urls) {
			// 		that.url = that.urls
			// 		that.getVideo()
			// 	}
			// }
		},
		setup() {
			let size = "large";
			let gutter = 12;
			let mobile = false;
			const screen = reactive({
				height: null,
				width: null,
			});

			const {
				width,
				height
			} = useWindowSize();
			updateWindoiw(width, height);

			function updateWindoiw(width, height) {
				screen.height = height._value;
				screen.width = width._value;
				if (screen.width >= 750) {
					size = 'large'
					gutter = 12
					mobile = false
				} else {
					size = 'small'
					gutter = 24
					mobile = true
				}
			}

			return {
				size,
				gutter,
				mobile
			}
		},

		methods: {

			getToken() {
				getToken({}).then(res => {
					if (res.code == 200) {
						sessionStorage.setItem("token", res.data);
					}
				})
			},
			getPaset(e) {
				let that = this
				setTimeout(function() {
					if (that.checked1) {
						that.getVideo()
					}
				}, 200)
			},
			getVideo() {
				let that = this
				if (!that.url) {
					ElMessage({
						message: "地址不能为空",
						type: "warning",
						duration: 1500
					})
					return false;
				} else if (that.ver(that.url)) {
					that.loading = true
					that.getAllVideo()
				}
			},
			backToHome() {
				this.$router.push({
					path: "/"
				})
			},
			ver(url) {
				let that = this
				if (url && !checkVaild(url, 'URL')) {
					ElMessage({
						message: "网址不正确",
						type: "warning",
						duration: 1500
					})
					that.url = ""
					return false;
				} else {
					return true
				}
			},
			pasteMe(e) {
				let that = this
				navigator.clipboard.readText().then((v) => {
						this.$message({
							message: "自动粘贴成功",
							type: "success"
						})
						that.url = v;
						if (that.checked1) {
							that.getVideo()
						}
					})
					.catch((v) => {
						this.$message({
							message: "获取剪贴板失败，可能浏览器没有开启权限",
							type: "warning"
						})
						console.log("获取剪贴板失败: ", v);
					});
			},
			handleChange(e) {
				console.log(e)
			},
			openUrl() {
				let that = this
				window.open(that.allData.url);
			},
			openIstero() {
				window.open("https://www.istero.com");
			},

			getAllVideo() {
				let that = this
				that.show = false
				getAllVideo({
					url: that.url,
					type: that.allData.flag
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: "获取成功",
							type: "success",
							duration: 1500
						})
						that.downList = res.data
						that.show = true
						that.loading = false
					} else {
						if (res.code == 505) {
							sessionStorage.removeItem("token");
							ElMessageBox({
								title: '提示', //MessageBox 标题
								message: '当前页面已失效，点击确定重新加载工具', //MessageBox 消息正文内容
								confirmButtonText: '确定', //确定按钮的文本内容
								showCancelButton: false, //是否显示取消按钮
								type: 'warning', //消息类型，用于显示图标
							}).then(() => {
								this.$router.go(0)
							}).catch(() => {});
						} else {
							ElMessage({
								message: res.message,
								type: "warning"
							})
						}
						that.loading = false
					}
				})
			},
			download(url) {
				let that = this
				ElNotification({
					title: '启动下载任务',
					message: "正在下载视频，请稍候查看",
					position: 'bottom-right',
					type: "success"
				})
				// https://www.instagram.com/p/ChkVM2pFA4X/?igshid=YmMyMTA2M2Y=
				if (that.mobile) {
					window.open(url)
				} else {
					if (that.allData.flag == 'instagram') {
						window.open(url)
					} else {
						var FileSaver = require('file-saver');
						FileSaver.saveAs(url, that.downList.full_text + ".mp4");
					}
				}
			}
		}

	}
</script>


<style>
	body {
		background: #f5f5f5;
		margin: 0 0;
		padding: 0 0;
	}

	.header {
		background: #ffffff;
		width: 100%;
		border-bottom: 1px solid #ededed;
		padding: 10px 0px
	}

	.header .c {
		display: flex;
		justify-content: space-between;
		padding: 15px;
		align-items: center;
		width: 90%;
		margin-left: auto;
		margin-right: auto;
	}

	.header .c .logo img {
		width: 60px;
		height: auto;
	}

	.mainBox {
		max-width: 80%;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		margin-top: 60px;
	}

	.mainBox .title {
		font-weight: 600;
		font-family: "Source Sans Pro";
		color: #2a2a2a;
		font-size: 40px;
		letter-spacing: -1px;
	}

	.mainBox .desc {
		color: #6d6d6d;
		font-size: 26px;
		margin-top: 25px;
		letter-spacing: 3px;
	}

	.mainBox .textBox {
		margin-top: 40px;
		margin-bottom: 10px;
	}

	.mainBox .checkBox {
		text-align: left
	}

	.mainBox .cardBox {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-top: 40px;
	}


	.mainBox .cardBox .c {}

	.mainBox .cardBox .c .imgBox {
		width: 100%;
	}

	.mainBox .cardBox .c .cBox {
		width: 100%;
		text-align: left;
		margin-left: 15px;
		font-size: 23px;
		margin-top: 10px;
		line-height: 30px;

	}

	.mainBox .cardBox .c .cBox .title {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;

	}

	.mainBox .cardBox .c .cBox .btnBox {
		margin-top: 10px;

	}


	.el-button .custom-loading .circular {
		margin-right: 6px;
		width: 18px;
		height: 18px;
		animation: loading-rotate 2s linear infinite;
	}

	.el-button .custom-loading .circular .path {
		animation: loading-dash 1.5s ease-in-out infinite;
		stroke-dasharray: 90, 150;
		stroke-dashoffset: 0;
		stroke-width: 2;
		stroke: var(--el-button-text-color);
		stroke-linecap: round;
	}



	.mainBox .collapse {
		padding: 50px;
		background: #ffffff;
		margin-top: 40px;
		text-align: left
	}

	.footer {
		padding: 30px 0px;
		font-size: 20px;
		text-align: center;
		color: #909090;
	}


	.footer .cLink {
		margin-top: 5px;
	}

	.footer .cLink a {
		color: #909090;
		text-decoration: none;
		font-size: 0.9rem;
	}

	@media screen and (min-width: 750px) {
		.header {
			background: #ffffff;
			width: 100%;
			border-bottom: 1px solid #ededed;
		}

		.header .c {
			display: flex;
			justify-content: space-between;
			padding: 15px;
			align-items: center;
			width: 80%;
			margin-left: auto;
			margin-right: auto;
		}

		.header .c .logo img {
			width: 50px;
			height: auto;
		}

		.mainBox {
			max-width: 60%;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
			margin-top: 60px;
		}

		.mainBox .title {
			font-weight: 600;
			font-family: "Source Sans Pro";
			color: #2a2a2a;
			font-size: 2.5rem;
			letter-spacing: -1px;
		}

		.mainBox .desc {
			color: #6d6d6d;
			font-size: 22px;
			margin-top: 8px;
			letter-spacing: 3px;
		}

		.mainBox .textBox {
			margin-top: 40px;
			margin-bottom: 10px;
		}

		.mainBox .checkBox {
			text-align: left
		}

		.mainBox .cardBox {
			width: 75%;
			margin-left: auto;
			margin-right: auto;
			margin-top: 40px;
		}


		.mainBox .cardBox .c {
			display: flex;
			justify-content: center;
		}

		.mainBox .cardBox .c .imgBox {
			width: 33%;
		}

		.mainBox .cardBox .c .cBox {
			width: 65%;
			text-align: left;
			margin-left: 15px;
			font-size: 13px;
			line-height: 20px;

		}

		.mainBox .cardBox .c .cBox .title {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;

		}

		.mainBox .cardBox .c .cBox .btnBox {
			margin-top: 10px;

		}


		.el-button .custom-loading .circular {
			margin-right: 6px;
			width: 18px;
			height: 18px;
			animation: loading-rotate 2s linear infinite;
		}

		.el-button .custom-loading .circular .path {
			animation: loading-dash 1.5s ease-in-out infinite;
			stroke-dasharray: 90, 150;
			stroke-dashoffset: 0;
			stroke-width: 2;
			stroke: var(--el-button-text-color);
			stroke-linecap: round;
		}



		.mainBox .collapse {
			padding: 30px;
			background: #ffffff;
			margin-top: 40px;
			text-align: left
		}

		.el-divider__text {
			background: #f5f5f5;
			color: #909090;
		}

		.footer {
			padding: 30px 0px;
		}
	}
</style>